.volya-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 8px;
  overflow: hidden; // Ensures the card content stays within the card boundary

  // For desktop or larger screens
  @media (min-width: 768px) {
    width: calc(33.333% - 12px); // Make sure 3 cards fit in a row with margin
    margin-right: 12px; // Space between cards
    flex: 1;
    min-width: 250px; // Sets a minimum width to prevent cards from shrinking too much
  }

  // Full width on smaller screens like mobile
  @media (max-width: 767px) {
    width: 100%; // Takes full width of mobile screen
    min-width: 100%; // Ensure full width on mobile
    // margin-right: 0;
  }

  .volya-card-img {
    width: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0; // Only the top corners are rounded
    flex-grow: 1; // Make the image grow to fill available space

    @media (max-width: 767px) {
      height: auto; // Image adjusts to the content size
    }
  }

  .volya-card-button {
    background-color: white;
    color: black;
    text-transform: uppercase;
    text-align: center;
    border-radius: 0 0 8px 8px; // Only bottom corners rounded
    text-transform: uppercase; // Ensures button text is uppercase
    font-family: 'Montserrat', sans-serif; // Adds the correct font
    font-size: 1.5rem; // Matches h2 size
    line-height: 2rem; // Matches h2 line height
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: -5px;
    padding: 14px;
    width: 100%; // Ensures button takes full width
    display: block;

    @media (min-width: 768px) {
      padding: 16px; // Slightly more padding on desktop
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }
}
