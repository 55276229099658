@import './App.scss';

.about-us {
  margin: 0 auto;
  padding: 40px 0;

  .about-us-title {
    margin-bottom: 50px;
    text-transform: uppercase;
    color: #212529;

    @media (max-width: 768px) {
      font-size: 2.2em;
      margin-left: 20px;
      margin-right: 20px;
      text-align: center;
      color: #032e3d;
      font-weight: bold;
    }
  }

  .pillars-image {
    display: flex;
    justify-content: center; // Center the image container

    img {
      max-width: 80%; // Prevent image from overflowing
      height: auto; // Maintain aspect ratio

      @media (max-width: 768px) {
        max-width: 130%;
        margin: 0px 0px 0px 0px;
      }
    }
  }

  .section {
    margin-top: 30px;
    margin-bottom: 30px;

    .description,
    .social-object-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      @media (min-width: 992px) {
        flex-direction: row;
      }

      @media (max-width: 768px) {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items */
        text-align: center; /* Center text content */
      }

      .text-section {
        flex: 1;
        padding: 20px;
        border-left: 4px solid $primary;
        max-width: 600px;

        @media (max-width: 992px) {
          border-left: none;
          padding-top: 0;
          padding-left: 0;
        }
      }

      .spaced-description {
        margin-top: 20px;
      }

      .image-section {
        flex: 1;
        text-align: center;

        .section-image {
          width: 100%;
          max-width: 400px;
          border-radius: 10px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        }
      }
    }
    @media (max-width: 768px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .values-section {
    background: url('/assets/patterns/round-dots.png') no-repeat center center;
    padding: 30px;
    margin: 50px 0;
    text-align: center;
    background-size: contain;

    .values-title {
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    .values-text {
      line-height: 1.8;
      color: #212529;
      font-size: 1.2em;

      strong {
        color: $danger;
        text-transform: uppercase;
      }
    }

    @media (max-width: 768px) {
      font-size: 0.9em;
      margin-left: 5px;
      margin-right: 5px;
      padding: 0px;
      text-align: center;
      background-size: auto;
    }
  }
  .axes-section {
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .info-box {
      flex: 1;
      position: relative; // Allow absolute positioning for the button
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: auto; // Ensures that each box has the same height
      border: 1px solid #ddd;
      border-radius: 10px;
      text-align: center;
      background-color: #fff;
      transition: all 0.3s ease-in-out;
      padding: 0; // Remove padding for uniform spacing

      // Button wrapper to control button placement
      .info-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0 30px; // Add padding for equal spacing at the bottom
      }

      .info-content {
        padding: 20px;
        flex-grow: 1; // Ensure it takes up remaining space above the button
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-image: url('/assets/patterns/left-corner.png');
        background-repeat: no-repeat; // Prevents tiling
        background-position: center center; // Centers the image
        background-size: cover; // Scales image to cover the container
      }

      .info-title {
        font-weight: 500;
        color: $warning;
        font-weight: bold;
        margin-bottom: 10px;
        text-transform: uppercase;
        @media (max-width: 768px) {
          font-size: 1.6em;
          margin-right: 2px;
          padding: 0px;
        }
      }

      .sub-title {
        color: black; // Keep subtitle color black
        margin-bottom: 10px;
        font-weight: 300;
        text-transform: uppercase;
      }

      .info-text {
        align-items: center;
      }

      &:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      }
    }

    // Ensure button style consistency
    .btn-axes {
      width: 80%;
      border-radius: 4px; // Slight rounding for the button
      background-color: $warning;
      color: white;
      padding: 8px 16px; // Regular padding

      &:hover {
        background-color: darken($warning, 20%);
      }
    }
  }

  .social-objects {
    margin-top: 60px;

    .social-object-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center; // Center-aligns the entire row on desktop

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }

      .social-object-text-list {
        flex: 2;
        margin-left: 20px;
        @media (max-width: 992px) {
          text-align: center;
          margin-left: 0px;
        }

        .social-object-item {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          color: $main-font-color;

          @media (max-width: 992px) {
            margin-bottom: 15px;
            flex-direction: column; // Stack icon above text on small screens
            align-items: center;
          }

          .social-object-icon {
            font-size: 50px;
            margin-right: 20px;
            color: $primary;
            width: 60px; // Set a uniform width for all icons
            min-width: 60px; // Prevent shrinking
            margin-right: 20px;
            text-align: center; // Center the icon within its box

            @media (max-width: 992px) {
              margin-right: 0; // Remove the right margin for stacked layout
              margin-bottom: 10px; // Add space below the icon when stacked
            }
          }

          .social-object-text {
            font-size: 1.2em;
            line-height: 1.4;
            @media (max-width: 992px) {
              text-align: center;
              margin-left: 0px;
              font-size: 1em;
            }
          }
        }
      }
    }
  }

  .team-section {
    margin-top: 60px;
    text-align: center;

    .team-description {
      margin: 30px auto;
      max-width: 800px;
    }

    .team-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;

      .team-member {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
        max-width: 200px;
        align-self: flex-start; // Ensure consistent alignment in the rows

        @media (max-width: 992px) {
          margin-top: none;
        }

        .avatar-image {
          border-radius: 50%;
          width: 150px;
          height: 150px;
          margin: 0 auto;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.05);
          }
        }

        .member-name {
          margin-top: 15px;
          font-weight: 600;
          color: $primary;
        }
      }
    }
  }

  .donate-card,
  .join-us {
    margin-top: 60px;
  }
}

.documents-content {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center; /* Center the content */
    text-align: center; /* Center-align the text content */
  }

  .image-section {
    flex: 1;
    text-align: center;

    .section-image {
      width: 100%;
      max-width: 400px;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  .text-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-left: none;

    .section-title {
      margin-bottom: 20px;
      text-transform: uppercase;
      font-size: 24px;
      color: black; // Keep the title in black as requested
    }

    @media (max-width: 768px) {
      align-items: center; /* Center align the text section */
      text-align: center; /* Center text inside the text section */
    }

    .document-list {
      padding-left: 20px; // Indentation for bullet points
      margin-top: 0;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        padding-left: 0px;
        align-items: center;
      }

      .document-link {
        position: relative;
        font-size: 1.1em;
        margin-bottom: 15px;
        color: $primary;
        display: flex;
        align-items: center;
        text-decoration: underline;

        &:hover {
          text-decoration: underline;
          color: darken($primary, 10%);
        }
      }
    }
  }
  @media (max-width: 768px) {
    .about-us-title {
      font-size: 1.8em; // Reduce the font size for main titles
      margin-left: 20px;
      margin-right: 20px; // Add side margins for better visual comfort
      text-align: center; // Center the title on mobile
    }
    .section h4,
    .values-title,
    .info-title {
      font-size: 1.4em; // Make section titles smaller on mobile
    }

    .pillars-image img {
      width: 100%; // Take full width of the screen
      height: auto;
    }
    .text-section {
      padding: 10px; // Less padding on small screens
    }

    .axes-section {
      flex-direction: column;
      .info-box {
        width: 100%; // Full width for each card
        margin-bottom: 20px; // Add spacing between cards
      }
    }
  }
}
