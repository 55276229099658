@import '../App.scss';

.aide-humanitaire-page {
  margin: 40px 0;

  .introduction-section {
    text-align: center;
    margin: 50px 0;

    .description {
      max-width: 800px;
      margin: 0 auto;
      line-height: 1.8;
    }

    .intro-image-container {
      margin: 20px auto;
      max-width: 400px; // Contain the image within a maximum width
    }

    .intro-image {
      width: 100%;
      height: auto;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
  }
  .description {
    display: flex;
    justify-content: space-between;
    align-items: center; // Align items at the top on larger screens
    margin-bottom: 60px;
    margin-top: 30px;

    @media (min-width: 992px) {
      flex-flow: row nowrap;
    }

    @media (max-width: 992px) {
      flex-direction: column; // Stack items vertically on smaller screens
      text-align: center; // Center the text on small screens
      align-items: center; // Center both text and image
    }

    .text-content {
      display: flex;
      flex-direction: column;
      justify-content: center; // Vertically center text
      text-align: right;
      flex: 1;
      margin-right: 30px; // Add spacing between text and image
      @media (max-width: 992px) {
        margin-right: 0; // Remove margin on smaller screens
        text-align: center; // Center text on small screens
      }

      .title {
        margin-bottom: 15px;
        text-transform: uppercase;
      }

      .subtitle {
        margin-bottom: 5px;
        text-transform: uppercase;
        color: $warning;
      }

      .main-text {
        line-height: 1.6;
        color: #212529; // Standard text color
        margin-bottom: 5px;
      }

      .example-title {
        margin-top: 10px;
        margin-bottom: 10px;
        text-transform: uppercase;
        color: $primary;
      }

      .example-list {
        list-style-type: none; /* Remove default bullets */
        padding-left: 0;

        li {
          position: relative;
          padding-left: 20px;
          margin-bottom: 8px;

          @media (min-width: 992px) {
            padding-left: 0px;
          }
        }
      }
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      img {
        width: 100%;
        max-width: 400px;
        height: auto;
        border-radius: 15px;
      }

      @media (max-width: 992px) {
        margin-top: 20px; // Add space between text and image on small screens
        text-align: right;
      }
    }
  }

  .description-inverse {
    display: flex;
    justify-content: space-between;
    align-items: center; // Align items at the top on larger screens
    margin-bottom: 60px;
    margin-top: 30px;

    @media (min-width: 992px) {
      flex-flow: row nowrap;
    }

    @media (max-width: 992px) {
      flex-direction: column; // Stack items vertically on smaller screens
      text-align: center; // Center the text on small screens
      align-items: center; // Center both text and image
    }

    .text-content {
      display: flex;
      flex-direction: column;
      justify-content: center; // Vertically center text
      text-align: left;
      flex: 1;
      margin-left: 30px; // Add spacing between text and image
      margin-top: 5px;
      @media (max-width: 992px) {
        margin-left: 0; // Remove margin on smaller screens
        text-align: center; // Center text on small screens
      }
      .title {
        margin-bottom: 15px;
        text-transform: uppercase;
      }

      .subtitle {
        margin-bottom: 5px;
        text-transform: uppercase;
        color: $warning;
        font-size: 1.1em;
      }

      .main-text {
        line-height: 1.6;
        color: #212529; // Standard text color
      }

      .example-title {
        margin-top: 10px;
        margin-bottom: 10px;
        text-transform: uppercase;
        color: $primary;
      }

      .example-list {
        list-style-type: none; /* Remove default bullets */
        padding-left: 0;

        li {
          position: relative;
          padding-left: 20px;
          margin-bottom: 8px;

          @media (min-width: 992px) {
            padding-left: 0px;
          }
        }
      }
    }

    .collecte-list {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      list-style: none;
      margin-top: 20px;
      justify-content: left; // Vertically center text
      text-align: left;

      .list-item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .liste-icon {
          font-size: 24px;
          margin-right: 15px;
          color: $primary; // Use the primary color defined in your App.scss
        }

        .list-text {
          font-size: 1.2em;
          line-height: 1.4;
          color: #212529; // Standard text color
        }
      }
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      img {
        width: 100%;
        max-width: 400px;
        height: auto;
        border-radius: 15px;
      }

      @media (max-width: 992px) {
        margin-top: 20px; // Add space between text and image on small screens
        text-align: left;
      }
    }

    .liste {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      img {
        border-radius: none !important;
        width: 100%;
        max-width: 400px;
        height: auto;
      }

      @media (max-width: 992px) {
        margin-top: 20px; // Add space between text and image on small screens
        text-align: left;
      }
    }
  }
  .section {
    margin: 60px 0;

    .initiatives-section {
      .initiative-box {
        padding: 30px 0;

        .image-container {
          flex-basis: 100%; // Ensure the image container maintains full width on small screens
          max-width: 400px; // Constrain image container size
          margin: 20px;
          text-align: center;

          .image-wrapper {
            width: 100%;
            max-width: 100%;
            img {
              width: 100%; // Make the image take full width of its container
              max-width: 100%; // Prevent it from exceeding container size
              height: auto;
              border-radius: 15px;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              object-fit: cover; // Prevent distortion
            }
          }
        }

        .text-container {
          flex-basis: 50%;
          text-align: left;
          margin: 0 20px;

          .initiative-title {
            color: $primary;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          .initiative-subtitle {
            color: $secondary;
            font-style: italic;
            margin-bottom: 15px;
          }

          .initiative-description,
          .initiative-extended-description {
            text-align: justify;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .ways-to-help-section {
    margin-top: 40px;

    .stats-section {
      display: grid;
      grid-template-columns: 1fr; // Default: 1 card per row
      gap: 20px;
      margin-bottom: 40px;
      margin-top: 20px;

      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr); // 2 cards per row on medium screens
      }

      @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr); // 3 cards per row on large screens
      }

      .info-box {
        display: flex;
        align-items: flex-start; // Align text at the top
        justify-content: center;
        height: 250px; // Uniform height for all cards
        border: 1px solid #ddd;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px;
        text-align: center;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease-in-out;
        background-image: url('/assets/patterns/left-yellow-dots.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        @media (max-width: 992px) {
          height: 300px;
        }

        .info-content {
          padding: 20px;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start; // Keep content at the top
          align-items: center;

          strong {
            color: $warning; // Use a primary blue color for emphasis
            font-weight: bold;
            text-transform: uppercase;
          }
        }

        .MuiTypography-root {
          font-size: 1.1rem !important; // Override Material-UI Typography styles
        }

        .info-title {
          color: #007bff; // Matching primary color for the title
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        &:hover {
          box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); // Subtle hover effect
        }
      }
    }
  }

  .centered-image {
    display: flex;
    justify-content: center;
    margin: 30px 0;

    .img-in-the-middle {
      max-width: 300px;
      width: 100%;
      height: auto;
    }
  }

  .collection-points {
    padding: 40px 0;
    text-align: center;

    .map-container {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); // Optional: Add a subtle shadow for the map
      border: 1px solid #ddd;
      border-radius: 10px;
      overflow: hidden;

      // Responsive Styling for Map Container
      @media (max-width: 991px) {
        width: 100%; // Full width on smaller screens
        height: 300px; // Specific height for smaller screens
        margin-bottom: 20px; // Space between map and address
      }
    }

    .address-section {
      display: flex;
      justify-content: center;
      align-items: center;

      .address-container {
        text-align: left; // Align text to the left for better readability
        max-width: 400px;
        margin: 0 auto;

        .points-title {
          text-transform: uppercase;
          margin-bottom: 10px;
          font-weight: 600;
        }

        .points-title {
          color: $warning; // Use a consistent primary color for titles
          text-transform: uppercase;
          margin-bottom: 10px;
          font-weight: 600;
        }

        h6 {
          font-size: 1.1em;
          color: #333; // Slightly darker color for standard text
        }

        p {
          margin-top: 20px;
          line-height: 1.5;
        }
      }
    }
  }
}
