@import '../App.scss';

.inclusion-page {
  //margin: 40px 0;

  .introduction-section {
    text-align: center;
    margin: 50px 0;

    .intro-image-container {
      margin: 20px auto;
      max-width: 400px; // Contain the image within a maximum width
    }

    .intro-image {
      width: 100%;
      height: auto;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
  }
  .description {
    display: flex;
    justify-content: space-between;
    align-items: center; // Align items at the top on larger screens
    margin-bottom: 60px;
    margin-top: 30px;

    @media (min-width: 992px) {
      flex-flow: row nowrap;
    }

    @media (max-width: 992px) {
      flex-direction: column; // Stack items vertically on smaller screens
      text-align: center; // Center the text on small screens
      align-items: center; // Center both text and image
    }

    .text-content {
      display: flex;
      flex-direction: column;
      justify-content: center; // Vertically center text
      text-align: right;
      flex: 1;
      margin-right: 30px; // Add spacing between text and image
      @media (max-width: 992px) {
        margin-right: 0; // Remove margin on smaller screens
        text-align: center; // Center text on small screens
      }

      .title {
        margin-bottom: 15px;
        text-transform: uppercase;
      }

      .subtitle {
        margin-bottom: 5px;
        text-transform: uppercase;
        color: $warning;
      }

      .main-text {
        line-height: 1.6;
        color: #212529; // Standard text color
        margin-bottom: 5px;
      }
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      img {
        width: 100%;
        max-width: 400px;
        height: auto;
        border-radius: 15px;
      }

      @media (max-width: 992px) {
        margin-top: 20px; // Add space between text and image on small screens
        text-align: right;
      }
    }
  }

  .description-inverse {
    display: flex;
    justify-content: space-between;
    align-items: center; // Align items at the top on larger screens
    margin-bottom: 60px;
    margin-top: 30px;

    @media (min-width: 992px) {
      flex-flow: row nowrap;
    }

    @media (max-width: 992px) {
      flex-direction: column; // Stack items vertically on smaller screens
      text-align: center; // Center the text on small screens
      align-items: center; // Center both text and image
    }

    .text-content {
      display: flex;
      flex-direction: column;
      justify-content: center; // Vertically center text
      text-align: left;
      flex: 1;
      margin-left: 30px; // Add spacing between text and image
      @media (max-width: 992px) {
        margin-left: 0; // Remove margin on smaller screens
        text-align: center; // Center text on small screens
      }
      .title {
        margin-bottom: 15px;
        text-transform: uppercase;
      }

      .subtitle {
        margin-bottom: 5px;
        text-transform: uppercase;
        color: $warning;
      }

      .main-text {
        line-height: 1.6;
        color: #212529; // Standard text color
      }
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      img {
        width: 100%;
        max-width: 400px;
        height: auto;
        border-radius: 15px;
      }

      @media (max-width: 992px) {
        margin-top: 20px; // Add space between text and image on small screens
        text-align: left;
      }
    }
  }

  .section {
    margin: 60px 0;

    .initiatives-section {
      .initiative-box {
        padding: 30px 0;

        .image-container {
          flex-basis: 100%; // Ensure the image container maintains full width on small screens
          max-width: 400px; // Constrain image container size
          margin: 20px;
          text-align: center;

          .image-wrapper {
            width: 100%;
            max-width: 100%;
            img {
              width: 100%; // Make the image take full width of its container
              max-width: 100%; // Prevent it from exceeding container size
              height: auto;
              border-radius: 15px;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              object-fit: cover; // Prevent distortion
            }
          }
        }

        .text-container {
          flex-basis: 50%;
          text-align: left;
          margin: 0 20px;

          .initiative-title {
            color: $primary;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          .initiative-subtitle {
            color: $secondary;
            font-style: italic;
            margin-bottom: 15px;
          }

          .initiative-description,
          .initiative-extended-description {
            text-align: justify;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .stats-section {
    display: grid;
    grid-template-columns: 1fr; // Default: 1 card per row
    gap: 20px;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr); // 2 cards per row on medium screens
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr); // 3 cards per row on large screens
    }

    @media (max-width: 780px) {
      display: flex;
      flex-direction: column;
    }

    .info-box {
      display: flex;
      align-items: flex-start; // Center the content vertically
      justify-content: center; // Center the content horizontally
      height: 350px; // Ensure uniform height for all cards
      //flex-direction: column;
      justify-content: space-between;
      border: 1px solid #ddd;
      border-radius: 10px;
      background-color: #fff;
      padding: 20px;
      text-align: center;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease-in-out;
      background-image: url('/assets/patterns/left-yellow-dots.png');
      background-repeat: no-repeat; // Prevents tiling
      background-position: center center; // Centers the image
      background-size: cover; // Scales image to cover the container
      margin: 0 auto; // Center the card container on small screens

      .info-content {
        padding: 20px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.1em;
        width: 100%; // Ensure the content fills the card

        strong {
          color: $primary;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      .info-title {
        color: $primary;
        text-transform: uppercase;
        margin-bottom: 10px;

        @media (max-width: 780px) {
          font-size: 1.6rem;
        }
      }

      &:hover {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
      }
      @media (max-width: 992px) {
        width: 90%; // Ensure full width without overflow
        height: auto; // Allow auto height for proper resizing
        margin: 15px auto; // Center align each card and add margin between

        .info-content {
          padding: 15px; // Slightly reduce padding
        }
      }

      // Specific styling for very small screens
      @media (max-width: 730px) {
        width: 100%; // Full width on small screens
        margin-right: 40px;
        font-size: 1em; // Slightly reduce text size for better fit
      }
    }
  }
  .centered-image {
    display: flex;
    justify-content: center;
    margin: 10px 0;

    .img-in-the-middle {
      max-width: 300px;
      width: 100%;
      height: auto;
    }
  }
}
