$primary: #0090c3;
$warning: #f0c717;
$danger: #de1925;
$main-background: #faf8ef;
$padded-card-border-radius: 15px;
$main-font-color: #212529;

:export {
  primary: $primary;
  padded-card-border-radius: $padded-card-border-radius;
}

@import '~bootstrap/scss/bootstrap';
@import '../node_modules/bootstrap/scss/reboot';

// Ensure that Montserrat is the default font for all elements
body,
button,
input,
textarea,
select,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
span,
div {
  font-family: 'Montserrat', sans-serif !important;
}

// Override Bootstrap or other imported styles that might reset the font
* {
  font-family: 'Montserrat', sans-serif !important;
}

.disabled-font {
  color: transparentize($main-font-color, 0.5);
}

ul {
  padding-left: inherit;
  list-style: none;
}

.btn {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  color: white !important;
}

.btn-light,
.btn-light:hover,
.btn-light:active,
.btn-light:visited {
  color: black !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid black !important;
}

.btn-light:hover {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

a {
  color: black;
  text-decoration: none;
}

h2 {
  text-transform: uppercase;
}

h4 {
  text-transform: uppercase;
  font-weight: 300;
}

body {
  background-color: #faf8ef;
  font-family: 'Montserrat', sans-serif;

  li {
    list-style-type: none;
    &:before {
      content: ''; // Remove any previous content settings that added bullet points
    }
  }
}

.App {
  position: relative;
  width: 100%;
  overflow-x: hidden;

  .navbar-body {
    margin-bottom: 60px;

    .nav-logo {
      height: 100px;
    }

    .navbar-row {
      flex-grow: 0;

      .volya-navbar {
        > * {
          color: black;
          align-self: center;
          margin-right: 8px;
        }

        .switch-lang {
          border: none;
          padding: 8px;
          background: none;
          color: black !important;
          box-shadow: none;
        }

        .faire-don-btn {
          background-color: #fcf4d1;
          color: black !important;
          border: none;
          padding: 5px;

          &:hover {
            text-decoration: underline;
            color: darken($primary, 10%);
            background-color: darken(#fcf4d1, 10%);
          }
        }
      }
    }
    .nav-link {

      @media (max-width: 768px) {
        box-shadow: 0px 0px 8px 4px $warning(0, 144, 195, 0.3); // Light blue glow around the link
        border-radius: 5px; // Slight rounding to smooth out the shadow
        margin: 4px 0; // Additional margin for spacing between links
        background-color: lighten($warning, 10%);
        padding: 8px;

        &:hover {
          color: darken($warning, 15%); // Darken the text color slightly on hover
        }
      }
    }

    .nav-toggle {
      @media (max-width: 768px) {
        background-color: lighten($warning, 10%);
        &:hover {
          color: darken($warning, 5%); // Darken the text color slightly on hover
        }
      }
    }
  }

  .body-container {
    margin-bottom: 50px;

    button {
      padding: 14px 16px;
    }

    .page-title {
      text-transform: uppercase;
      margin-bottom: 50px;
      color: #032e3d;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 2em; // Reduce the font size for main titles
        margin-left: 0px;
        padding-right: 0px;
        margin-right: 20px; // Add side margins for better visual comfort
        text-align: left; // Center the title on mobile
      }
    }

    .intro-title {
      @media (max-width: 768px) {
        font-size: 1.8em; // Reduce the font size for main titles
        text-align: left; // Center the title on mobile
      }
    }
  }

  @media (max-width: 768px) {
    // Body text and general typography
    body {
      font-size: 0.9em; // Slightly smaller text for better readability on mobile
      line-height: 1.5;
    }
  }

  .footer-container {
    margin-top: 60px;
    width: 100vw;
    background-color: $warning;

    .footer-content {
      display: flex;
      flex-flow: row wrap;
      padding-top: 40px;
      padding-bottom: 30px;
      justify-content: center;

      @media (min-width: 992px) {
        .slogan {
          flex-basis: 20%;
        }
        .link-categories {
          flex-basis: 80%;
        }
      }

      @media (min-width: 1200px) {
        .slogan {
          flex-basis: 40%;
        }
        .link-categories {
          flex-basis: 60%;
        }
      }
      @media (max-width: 767px) {
        flex-direction: column; // Stack content vertically
      }

      .slogan {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 20px;

        img {
          width: 80px;
        }
        p {
          flex-basis: 70%;
        }

        @media (min-width: 992px) {
          align-items: flex-start;
          justify-content: flex-start;

          p {
            flex-basis: 100%;
          }
        }
      }

      .socials {
        flex-basis: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        svg {
          width: 44px;
          height: 44px;
          margin-right: 16px;
          color: $primary;
        }

        button {
          margin-left: 16px;
        }
      }

      .link-categories {
        margin-top: 50px;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        flex-basis: 50%;
        margin-bottom: 50px;

        @media (max-width: 767px) {
          flex-direction: column; // Stack all links in a single column on mobile
          align-items: center; // Center align the categories
          margin-top: 20px;
          width: 100%; // Take full width on mobile to avoid shifting
        }

        .link-groups {
          margin: 0; // Remove default margin for consistent alignment
          padding: 0; // Remove padding for full-width alignment
          width: 100%; // Make each group take the full width
          text-align: left; // Ensure the text is aligned to the left
          margin-bottom: 20px; // Add vertical space between groups on mobile
          flex-basis: 100%; // Each link group takes full width on mobile

          @media (max-width: 767px) {
            flex-basis: 100%; // Each link group takes full width on mobile
            margin: 0; // Remove any left/right margins that might cause shifting
            padding: 0; // Remove padding for full-width alignment
            text-align: left; // Ensure text inside the group is left-aligned
            padding-left: 20px; // Slight left padding for text spacing
          }
        }

        @media (min-width: 576px) {
          flex-wrap: nowrap;
          justify-content: flex-end;
        }

        h6 {
          margin-bottom: 10px;
          text-transform: uppercase;
        }

        ul {
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          margin-top: unset;
          padding: 0; // Remove padding for better alignment
          margin: 0; // Remove top margin

          li {
            margin-bottom: 10px;

            @media (max-width: 767px) {
              padding-left: 0; // Remove default list padding to align correctly
              align-items: flex-start; // Align list items to the start
              width: 100%; // Take full width to ensure proper alignment
            }

            a {
              color: $main-font-color;
              text-decoration: none;
              display: block; // Ensure the links take up the full width
              width: 100%; // Full width for the anchor tags
            }

            &.contacts a {
              text-transform: lowercase;
            }

            span.address {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .copyright {
    background-color: $warning;
    display: flex;
    flex-flow: row wrap;
    padding-top: 10px;
    padding-bottom: 5px;
    justify-content: center;
    text-align: center;

    @media (min-width: 992px) {
      position: absolute; // Position the copyright section absolutely
      bottom: 0; // Align it to the bottom of the container
      left: 0;
      right: 0;
      margin: 0 auto; // Center it horizontally within the container
      padding: 15px 0; // Add padding to prevent overlap
      background-color: $warning; // Reapply background to ensure it covers full width
   //   padding-right: 50px;
    }

    @media (max-width: 767px) {
      order: 2; // Ensure copyright is at the bottom on mobile
      margin-top: 20px; // Add some spacing above
    }
  }

  .top-background-container {
    height: 500px;
    width: 100%;
    background: linear-gradient(180deg, #f0c611 0%, rgba(240, 198, 17, 0.71) 72.4%, rgba(240, 198, 17, 0) 100%);
    position: absolute;
    top: 0px;
    z-index: -1;

    > div {
      width: 600px;
      height: 600px;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url('~/public/dot-group.png');
      background-size: 500px 500px;
      background-repeat: no-repeat;
      background-position-x: right;
      background-position-y: -60%;
    }
  }
}

.img-in-the-middle {
  margin-top: 50px;
  width: 50%;
  align-self: center;

  @media (min-width: 992px) {
    width: 300px;
  }
}

.learn-more-button {
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.22);
  padding: 4px 24px;
  color: white;
  margin-top: 24px;
}

.transparent-CTA-btn {
  @extend .btn;
  text-transform: uppercase;
  padding: 12px;
  color: black;
  border: none;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.22);
}
