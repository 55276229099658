.partners-volya-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;

  .partners-volya-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    line-height: 59px;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 40px;
    text-align: center; /* Ensure title is centered */

    @media (max-width: 768px) {
      font-size: 36px; /* Smaller font size for tablets */
      line-height: 45px;
    }

    @media (max-width: 480px) {
      font-size: 24px; /* Further reduce font size for mobiles */
      line-height: 32px;
    }
  }

  .partners-volya-grid-wrapper {
    display: flex;
    justify-content: center;
    width: 100%; /* Wrapper should take full width */
    max-width: 1249px; /* Max width to ensure consistent centering */
  }

  .partners-volya-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(194px, 1fr)); /* Dynamic columns */
    gap: 56px;
    justify-items: center; /* Center each item in its cell */
    align-items: center;
    width: 100%; /* Full width to ensure grid alignment */
    max-width: 1249px; /* Limit grid width */
    margin: 0 auto;

    /* Adjustments for smaller screens */
    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr); /* 3 columns on medium screens */
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on small screens */
      gap: 32px; /* Reduce gap between images */
    }

    @media (max-width: 480px) {
      grid-template-columns: 1fr; /* 1 column on extra small screens */
      gap: 24px; /* Further reduce gap for smaller screens */
    }

    .partners-volya-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .partners-volya-image {
        width: 194px;
        height: 194px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #f0f0f0;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        /* Responsive image adjustments */
        @media (max-width: 768px) {
          width: 140px;
          height: 140px;
        }

        @media (max-width: 480px) {
          width: 100px;
          height: 100px;
        }
      }

      .partners-volya-name {
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #000;
        width: 134px;
        word-wrap: break-word;
        margin-top: 10px;

        /* Responsive text size adjustment */
        @media (max-width: 768px) {
          font-size: 18px; /* Smaller text on tablets */
          line-height: 22px;
        }

        @media (max-width: 480px) {
          font-size: 16px; /* Further reduce text size on mobiles */
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .partners-volya-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 32px;
    }
  }
}
