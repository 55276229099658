@import './App.scss';

.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;

  @media (max-width: 760px) {
    margin: 5px 5px; // Adjust margins for small screens
    width: 100%; // Ensure full width usage on small screens
  }

  h1 {
    font-size: 48px;
    margin-bottom: 24px;
    text-align: center;
    color: #032e3d; // Dark primary color for the heading
  }

  .description {
    text-align: center;
    max-width: 600px;
    margin-bottom: 50px;
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555; // Subtle color for description text

    @media (max-width: 760px) {
      max-width: 100%;
      font-size: 1rem;
      padding: 5px 5px;
      line-height: 1.5;
    }
  }

  .right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; // Add spacing at the top for separation

    span {
      margin-bottom: 16px;
      font-size: 1.2rem;
      text-align: center;
      line-height: 1.5;
      color: #333;

      &.email {
        font-size: 1.4rem;
        text-transform: uppercase;
        color: $warning;
        font-weight: bold;
        @media (max-width: 760px) {
          font-size: 1.1rem;
          margin: 0 0;
          padding: 0 0;
        }
      }
    }

    .socials {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      flex-wrap: wrap;

      a {
        margin: 5 15px; // Slightly larger space between icons
        color: #333;
        transition:
          transform 0.3s,
          color 0.3s; // Smooth transition for hover effects

        @media (max-width: 760px) {
          margin: 0 0; // Adjust space between icons on small screens
        }

        &:hover {
          color: $primary; // Change color on hover to primary blue
          transform: scale(1.1); // Slightly enlarge the icon on hover
        }
      }

      svg {
        width: 48px; // Increase icon size to 48px
        height: 48px;
        transition:
          color 0.3s,
          transform 0.3s; // Animate color and scaling on hover
      }
    }

    .linktree-link {
      font-size: 1.4rem;
      margin: 20px 0;
      color: $primary;
      text-transform: uppercase;
      text-decoration: underline;
      transition: color 0.3s;

      &:hover {
        color: #032e3d; // Dark primary color for the heading
      }
    }
  }

  @media (min-width: 992px) {
    .description {
      max-width: 800px; // Increase description width on large screens
    }

    .socials svg {
      width: 50px; // Increase icon size slightly on larger screens
      height: 50px;
    }

    .email {
      font-size: 1.6rem;
    }
  }
  .centered-image {
    display: flex;
    justify-content: center;

    .img-in-the-middle {
      max-width: 300px;
      margin-bottom: 10px;
      width: 100%;
      height: auto;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    .img-calendar {
      max-width: 150px;
    }
  }
}
