.padded-card {
  margin-top: 24px;
  padding: 24px 12px;
  display: flex;
  flex-flow: column;
  justify-content: space-between; /* Maintain equal spacing */
  align-items: center;
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.22);
  height: 100%; /* Ensures equal height for all cards */
  border-radius: 8px; /* Optional: Adds a bit of rounding for better aesthetics */

  @media (min-width: 992px) {
    flex-flow: row nowrap;
    width: 100%;
    justify-content: flex-start;
    gap: 30px; /* Reduced gap between image and text */
  }

  img {
    width: 100%;
    border-radius: 8px; /* Rounds image corners */

    @media (min-width: 992px) {
      max-width: 220px;
      max-height: 220px;
    }
  }

  .content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 992px) {
      margin-right: 20px;
      align-items: flex-start;
    }

    h4 {
      text-align: center;
      margin-top: 1em;
      font-family: 'Montserrat', sans-serif;
      text-transform: uppercase;

      @media (min-width: 992px) {
        text-align: left;
        margin-top: 0;
        font-size: 24px;
      }
    }

    span {
      padding-top: 16px;
      font-family: 'Montserrat', sans-serif;
      color: #333;
      text-align: center;

      @media (min-width: 992px) {
        padding-top: 12px;
        text-align: left;
      }
    }
  }

  .learn-more-button {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Keep button width consistent */
    padding: 10px 20px; /* Add some padding for a balanced look */

    @media (min-width: 992px) {
      margin-top: 0;
      width: auto; /* Let the button width adapt on larger screens */
    }

    svg {
      margin-left: 10px;
      margin-right: 10px; /* Added margin to keep the arrow icon away from the border */
    }
  }
}