@import './App.scss';

.volya-about-us-container,
.partner-container,
.events-container {
  margin-top: 50px;
}

.home-new {
  display: flex;
  flex-flow: column;
  align-items: center;

  .banner-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    margin: auto 2.5em 130px 2.5em;

    h1,
    p {
      text-align: center;
    }

    button {
      margin-top: 45px;
    }
  }

  .goal-photos-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 20px;
    }
  }

  .volya-about-us-container {
    position: relative;
    margin-top: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    text-align: center;

    img {
      position: absolute;
      width: 300px;
      top: 20px;
      left: 0px;
      z-index: -1;
    }

    @media (min-width: 992px) {
      .dummy {
        width: 500px;
      }
    }

    .main {
      width: 100%;
      padding: 15px;
      border-radius: $padded-card-border-radius;
      background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      flex-flow: column;
      align-items: center;

      h2,
      button {
        text-transform: uppercase;
        margin-top: 10px;
      }
    }
  }

  .partner-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    h1 {
      text-transform: uppercase;
    }

    @media (min-width: 992px) {
      width: 100%;
    }

    ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      align-items: baseline;
      margin-top: 25px;

      @media (min-width: 992px) {
        width: 100%;
      }

      li {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 150px;

        img {
          width: 80%;
          margin-bottom: 5px;
        }

        p {
          text-align: center;
          font-size: 14px;
        }

        @media (min-width: 992px) {
          img {
            width: 150px;
          }
          p {
            font-size: inherit;
          }
        }
      }
    }
  }

  .padded-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Reduced gap between cards */
    margin: 40px 0;

    @media (min-width: 768px) {
      gap: 32px; /* Slightly increased gap for larger screens */
    }

    @media (min-width: 992px) {
      justify-content: space-between; /* Space cards evenly on larger screens */
      gap: 24px; /* Adjust gap to balance the layout */
    }
  }

  .events-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: auto;

    ul {
      white-space: nowrap;
      overflow-y: scroll;
      width: 94vw;

      @media (min-width: 992px) {
        width: 100%;
      }

      li {
        display: inline-block;
        width: 80%;

        &:nth-of-type(1) {
          width: calc(80% - 12px);
        }
        &:not(:nth-of-type(1)) {
          padding-left: 12px;
        }
        p {
          white-space: normal;
        }

        @media (min-width: 992px) {
          & {
            width: 300px;
          }
          &:nth-of-type(1) {
            width: calc(300px - 12px);
          }
        }
      }
    }

    button {
      margin-top: 20px;
    }
  }
}
