@import 'App.scss';

.faire-don {
  display: flex;
  flex-flow: column;

  h1 {
    font-size: 74px;
    align-self: flex-start;
    margin-bottom: 24px;
  }
  .CTA-group {
    display: flex;
    flex-flow: column;
    height: fit-content;
    margin-top: 130px;

    .left-column {
      margin-bottom: 20px;

      &::after {
        margin-top: 40px;
        content: 'OU';
      }
    }

    .left-column,
    .right-column {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      * {
        text-transform: uppercase;
      }
    }

    @media (min-width: 992px) {
      & {
        flex-flow: row nowrap;

        .left-column::after {
          margin-top: 0;
          content: none;
        }

        .left-column,
        .right-column {
          flex-basis: 50%;
        }
      }
    }

    img {
      width: 25%;
      position: absolute;
      z-index: -1;
      @media (max-width: 920px) {
        width: 50%;
      }

    }

    .big-btn {
      padding: 40px 35px;
      width: fit-content;
    }

    .right-column {
      span:first-child,
      span:nth-child(2),
      span:nth-child(5) {
        margin-bottom: 25px;
      }
      span:nth-child(3) {
        margin-bottom: 15px;
      }
      span:nth-child(2),
      span:nth-child(6) {
        font-size: 1.1rem;
        font-weight: 600;
      }
    }
  }
  .utilisation-dons {
    background-color: white;
    padding: 40px 50px;
    margin-top: 50px;
    border-radius: 8px;
    @media (max-width: 920px) {
      padding: 0px 0px;
    }

    h2,
    h4,
    p {
      margin-bottom: 30px;
    }

    li {
      margin-bottom: 15px;
      strong {
        color: $primary;
        text-transform: uppercase;
      }
    }

    h2 {
      text-transform: uppercase;
    }

    h4 {
      font-weight: 300;
      text-transform: uppercase;
    }
  }
  .why-help {
    max-width: 800px;
    margin-top: 50px;
    padding: 30px;
    text-align: center;
  }
}

.centered-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 15px;
  text-align: center;

  h4 {
    margin-bottom: 20px;
    text-transform: uppercase;
    color: $warning;
    font-weight: bolder;
  }

  p {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.6;
  }

  strong {
    text-transform: uppercase;
    font-weight: normal;
    color: $primary;
  }

  .img-in-the-middle {
    width: 100%;
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
}
