@import './App.scss';

.kalynantes {
  // display: flex;
  // flex-flow: column;

  .description {
    display: flex;
    justify-content: space-between;
    align-items: center; // Align items at the top on larger screens
    margin-bottom: 60px;
    margin-top: 30px;

    @media (min-width: 992px) {
      flex-flow: row nowrap;
    }

    @media (max-width: 992px) {
      flex-direction: column; // Stack items vertically on smaller screens
      text-align: center; // Center the text on small screens
      align-items: center; // Center both text and image
    }

    .text-content {
      display: flex;
      flex-direction: column;
      justify-content: center; // Vertically center text
      text-align: right;
      flex: 1;
      margin-right: 30px; // Add spacing between text and image
      @media (max-width: 992px) {
        margin-right: 0; // Remove margin on smaller screens
        text-align: center; // Center text on small screens
      }
      .subtitle {
        margin-bottom: 15px;
      }

      .main-text {
        line-height: 1.6;
        color: #212529; // Standard text color
      }
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      img {
        width: 100%;
        max-width: 400px;
        height: auto;
        border-radius: 15px;
      }

      @media (max-width: 992px) {
        margin-top: 20px; // Add space between text and image on small screens
        text-align: right;
      }
    }
  }

  a {
    align-self: center;
    margin-top: 2em;
  }

  .section {
    margin: 30px 0;
    padding: 0 20px;

    h4 {
      text-transform: uppercase;
      margin-bottom: 15px;
    }
  }

  .calendar-image {
    display: flex;
    justify-content: center; // Center the image container
    margin: 30px 0; // Add space above and below the image

    img {
      max-width: 80%; // Prevent image from overflowing
      height: auto; // Maintain aspect ratio
      border-radius: 10px; // Optional: Add rounded corners if desired
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Optional: Add subtle shadow for better visual appeal
    }
  }

  .age-groups-container {
    border-radius: 20px;
    display: flex;
    // flex-flow: column;
    justify-content: center;
    font-size: 1.1em;
    flex-wrap: wrap;

    .age-group-card {
      background-color: #f9f9f9;
      text-align: center;
      padding: 20px;
      margin: 15px;
      border-radius: 15px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      min-height: 270px;
      width: calc(25% - 30px); // Default: Four cards per row on large screens

      background-image: url('/assets/patterns/left-yellow-dots.png');
      background-repeat: no-repeat; // Prevents tiling
      background-position: center center; // Centers the image
      background-size: cover; // Scales image to cover the container

      @media (max-width: 992px) {
        width: calc(50% - 30px); // Adjust to two cards per row
      }

      @media (max-width: 768px) {
        width: calc(100% - 10px); // Full-width for single-column layout on small screens
        margin: 5px; // Reduce margin for narrow screens
      }

      .group-title {
        color: $warning; // Yellow color for group titles
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 1.3em;
      }

      .group-description {
        color: #212529;
      }
    }
  }
  .subject-list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    list-style: none;
    margin-top: 20px;

    .subject-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .subject-icon {
        font-size: 24px;
        margin-right: 15px;
        color: $primary; // Use the primary color defined in your App.scss
      }

      .subject-text {
        font-size: 1.2em;
        line-height: 1.4;
        color: #212529; // Standard text color
      }
    }
  }

  .register-button {
    margin-top: 20px;
    align-self: center;
  }

  .team-section {
    margin-top: 60px;
    text-align: center;

    .team-description {
      margin: 30px auto;
      max-width: 800px;
    }

    .team-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;

      .team-member {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
        max-width: 200px;
        align-self: flex-start; // Ensure consistent alignment in the rows

        @media (max-width: 992px) {
          margin-top: none;
        }

        .avatar-image {
          border-radius: 50%;
          width: 150px;
          height: 150px;
          margin: 0 auto;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
          transition: transform 0.3s ease;
          overflow: hidden; // Ensure content doesn't overflow

          &:hover {
            transform: scale(1.05);
          }
        }

        .member-name {
          margin-top: 15px;
          font-weight: 600;
          color: $primary;
        }
      }
    }
  }
  .centered-image {
    display: flex;
    justify-content: center;
    margin: 10px 0;

    .img-in-the-middle {
      max-width: 300px;
      width: 100%;
      height: auto;
    }
  }
  @media (max-width: 920px) {
    .team-title {
      font-size: 1.7rem;
      margin-right: 10px;
    }
  }
}
