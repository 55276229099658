@import 'App.scss';

.events {
  display: flex;
  flex-flow: column;

  h1 {
    font-size: 74px;
    align-self: flex-start;
    margin-bottom: 24px;
  }

  .description {
    margin-bottom: 50px;
  }

  .h5,
  .h6 {
    font-weight: 300;
  }

  .h6:first-child,
  .h6:nth-child(2) {
    margin-bottom: 15px;
  }

  .h5 {
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
  }

  @media (min-width: 992px) {
    .description,
    ul {
      width: 70%;
      padding-left: unset;
    }

    ul {
      .card {
        flex-flow: row;
        img,
        > div {
          width: 50%;
          height: 100%;
        }
      }
    }
  }

  ul {
    display: flex;
    flex-flow: column;
    padding-left: 0;

    li {
      margin-bottom: 15px;
      width: 100%;
      .card {
        background-color: $main-background;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.22);
      }
    }
  }
}
