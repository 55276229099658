@import '../App.scss';

.sensibilisation-page {
  margin: 40px 0;

  .introduction-section {
    text-align: center;
    margin: 50px 0;

    .intro-image-container {
      margin: 20px auto;
      max-width: 400px; // Contain the image within a maximum width
    }

    .intro-image {
      width: 100%;
      height: auto;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
  }
  .description {
    display: flex;
    justify-content: space-between;
    align-items: center; // Align items at the top on larger screens
    margin-bottom: 60px;
    margin-top: 30px;

    @media (min-width: 992px) {
      flex-flow: row nowrap;
    }

    @media (max-width: 992px) {
      flex-direction: column; // Stack items vertically on smaller screens
      text-align: center; // Center the text on small screens
      align-items: center; // Center both text and image
    }

    .text-content {
      display: flex;
      flex-direction: column;
      justify-content: center; // Vertically center text
      text-align: right;
      flex: 1;
      margin-right: 30px; // Add spacing between text and image

      @media (max-width: 992px) {
        margin-right: 0; // Remove margin on smaller screens
        text-align: center; // Center text on small screens
      }

      .title {
        margin-bottom: 15px;
        text-transform: uppercase;
      }

      .subtitle {
        margin-bottom: 5px;
        text-transform: uppercase;
        color: $warning;
      }

      .main-text {
        line-height: 1.6;
        color: #212529; // Standard text color
        margin-bottom: 5px;
      }

      .example-title {
        margin-top: 10px;
        margin-bottom: 10px;
        text-transform: uppercase;
        color: $primary;
      }

      .example-list {
        list-style-type: none; /* Remove default bullets */
        padding-left: 0;

        li {
          position: relative;
          margin-bottom: 8px;
        }
      }
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      img {
        width: 100%;
        max-width: 400px;
        height: auto;
        border-radius: 15px;
      }

      @media (max-width: 992px) {
        margin-top: 20px; // Add space between text and image on small screens
        text-align: right;
      }
    }
  }

  .description-inverse {
    display: flex;
    justify-content: space-between;
    align-items: center; // Align items at the top on larger screens
    margin-bottom: 60px;
    margin-top: 30px;

    @media (min-width: 992px) {
      flex-flow: row nowrap;
    }

    @media (max-width: 992px) {
      flex-direction: column; // Stack items vertically on smaller screens
      text-align: center; // Center the text on small screens
      align-items: center; // Center both text and image
    }

    .text-content {
      display: flex;
      flex-direction: column;
      justify-content: center; // Vertically center text
      text-align: left;
      flex: 1;
      margin-left: 30px; // Add spacing between text and image
      @media (max-width: 992px) {
        margin-left: 0; // Remove margin on smaller screens
        text-align: center; // Center text on small screens
      }
      .title {
        margin-bottom: 15px;
        text-transform: uppercase;
      }

      .subtitle {
        margin-bottom: 5px;
        text-transform: uppercase;
        color: $warning;
        font-size: 1.1em;
      }

      .main-text {
        line-height: 1.6;
        color: #212529; // Standard text color
      }

      .example-title {
        margin-top: 10px;
        margin-bottom: 10px;
        text-transform: uppercase;
        color: $primary;
      }

      .example-list {
        list-style-type: none; /* Remove default bullets */
        padding-left: 0;

        li {
          position: relative;
          margin-bottom: 8px;
        }
      }
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      img {
        width: 100%;
        max-width: 400px;
        height: auto;
        border-radius: 15px;
      }

      @media (max-width: 992px) {
        margin-top: 20px; // Add space between text and image on small screens
        text-align: left;
      }
    }
  }
  .section {
    margin: 60px 0;

    .initiatives-section {
      .initiative-box {
        padding: 30px 0;

        .image-container {
          flex-basis: 100%; // Ensure the image container maintains full width on small screens
          max-width: 400px; // Constrain image container size
          margin: 20px;
          text-align: center;

          .image-wrapper {
            width: 100%;
            max-width: 100%;
            img {
              width: 100%; // Make the image take full width of its container
              max-width: 100%; // Prevent it from exceeding container size
              height: auto;
              border-radius: 15px;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              object-fit: cover; // Prevent distortion
            }
          }
        }
        @media (min-width: 800px) {
          padding-left: 0px;
        }

        .text-container {
          flex-basis: 50%;
          text-align: left;
          margin: 0 20px;

          .initiative-title {
            color: $primary;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          .initiative-subtitle {
            color: $secondary;
            font-style: italic;
            margin-bottom: 15px;
          }

          .initiative-description,
          .initiative-extended-description {
            text-align: justify;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .centered-image {
    display: flex;
    justify-content: center;
    margin: 30px 0;

    .img-in-the-middle {
      max-width: 300px;
      width: 100%;
      height: auto;
    }
  }
}
