.fact-pages-nav {
    display: flex;
    justify-content: center; /* Centers the nav horizontally */
    align-items: center; /* Centers the nav vertically */
    height: 100px; /* Adjust as needed */
    margin-top: 20px; /* Optional margin for spacing */
  
    ul {
      display: flex; /* Set the list to display as a flex container */
      justify-content: center; /* Center the items horizontally */
      align-items: center; /* Center the items vertically */
      list-style: none; /* Remove default list styling */
      padding: 0;
      margin: 0;
    }
  
    li {
      margin: 0 20px; /* Space between each link */
    }
  
    .learn-more-button {
      display: flex;
      align-items: center; /* Center the icons vertically */
      text-decoration: none; /* Remove default link styling */
      color: #fff; /* Make sure the button text color is visible */
    }
  }